<template>
  <div class='ipone'>
    <div class="image">
      <img class="img" src="@/assets/ipone/20170421055101375.png" alt="">
    </div>
    <div class="floor">
      <img src="@/assets/ipone/2-1G0311K0324O.png" alt="">
    </div>
    <div class="banner">
      <img class="img" src="@/assets/ipone/2-1G109144HL63.png" alt="">
      <img class="img" src="@/assets/ipone/2-1G109144K1F0.png" alt="">
      <img class="img" src="@/assets/ipone/2-1G109144P4B4.png" alt="">
    </div>
    <div class="floor">
      <img src="@/assets/ipone/2-1G0311P315U3.png" alt="">
    </div>
    <div class="test">
      <div class="test1">针对政府部门及大型企业，移动门户、安全管理、业务集成服务引擎作为主要构成。</div>
      <div class="test1">基于Java的灵活框架，同时提供一套基于HTML5自主研发的业务公共组件及可视化配置工具，进一步提升开发效率。</div>
      <div class="test1"> 基于应用程序商店模式，企业可借助此平台自主扩展新应用或集成已有应用，实现可持续管理与发展，帮助企业实现移动化安全、高效落地。</div>
    </div>
    <div class="floor">
      <img src="@/assets/ipone/2-1G0311P42V92.png" alt="">
    </div>
    <div class="image1">
      <img class="img1" src="@/assets/ipone/2-1G103160032435.jpg" alt="">
    </div>
    <div class="floor">
      <img src="@/assets/ipone/2-1G103152F93P.png" alt="">
    </div>
    <div class="image1">
      <img class="img1" src="@/assets/ipone/2-1G10316005BN.jpg" alt="">
    </div>
    <div class="floor">
      <img src="@/assets/ipone/2-1G103152K2L6.png" alt="">
    </div>
    <div class="image1">
      <img class="img1" src="@/assets/ipone/2-1G1031601151S.jpg" alt="">
    </div>
    <div class="floor">
      <img src="@/assets/ipone/2-1G103152S2C7.png" alt="">
    </div>
    <div class="test">
      <div class="test1">后端集成：实现与原有IT资产的无缝对接，比如ERP、OA、供应链、人力资源、生产管理等系统。</div>
      <div class="test1">应用开发：多种开发功能组件可以实现积木式快速开发，一次开发，实现Android、iOS全终端覆盖。</div>
      <div class="test1"> 统一管理：实现跨平台统一、安全管理，权限管理、身份管理、消息推送等功能，完美实现应用的发布、升级和运维。</div>
      <div class="test1"> 开发工具：优势移动云平台拥有丰富的跨平台开发工具，灵活的能力插件扩展规范，允许开发者将自己的原生能力或服务注册到能力引擎库。</div>
    </div>
    <div class="floor">
      <img src="@/assets/ipone/2-1G103152942607.png" alt="">
    </div>
    <div class="image1">
      <img class="img1" src="@/assets/ipone/2-1G103153003414.jpg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'vue',
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style scoped lang='less'>
.image{
  margin-top: 0.1493rem;
  .img{
    width: 100%;
    height: 2.9851rem;
  }
}
.floor{
  // width: 7.4627rem;
  // width: 100%;
  margin: 0 0.4478rem;
  img{
    width: 100%;
  }
}
.banner{
  height: 1.0448rem;
  // width: 100%;
  margin-left: 0.4478rem;
  .img{
    width: 0.7463rem;
    height: 0.7463rem;
  }
}
.test{
  // height: 1.0448rem;
  margin-left: 0.4478rem;
  .test1{
    font-size: 0.1045rem;
    line-height: 0.2985rem;
  }
}
.image1{
  // width: 100%;
  margin-left: 0.4478rem;
  .img1{
    width: 6.7164rem;
  }
}
</style>